export function getUrlParams(name) {
    if (name == null || name === 'undefined') { 
      return null
    }
    var searchStr = decodeURIComponent(window.location.href).replace('?', '&')
    var infoIndex = searchStr.indexOf(name + '=')
    if (infoIndex === -1) { 
      return null
    }
    var searchInfo = searchStr.substring(infoIndex + name.length + 1)
    var tagIndex = searchInfo.indexOf('&')
    if (tagIndex !== -1) { 
      searchInfo = searchInfo.substring(0, tagIndex)
    }
    return searchInfo
}