import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import { getUrlParams } from '../utils/index'  // 工具函数：获取

Vue.prototype.$axios = axios

function getServerConfig() {  // 定义
  axios.get('./config.json').then(res => {  // 请求上面描述的本地配置文件
    const urlDev = getUrlParams('env')  // 当 URL 上存在 env=prod 时请求地址为生产环境的
    Vue.prototype.BASE_URL = urlDev == 'prod' ? res.data.BASE_URL_PROD : res.data.BASE_URL_DEV 
    console.log('接口配置的基础地址', Vue.prototype.BASE_URL)
    new Vue({
      el: '#app',
      router,
      store,
      render: h => h(App)
    })
  })
}
  
getServerConfig()

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);


import "./assets/styles/main.css";

import ucHead from '@/components/ucHead'
Vue.component('ucHead', ucHead)



Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
