<template>
	<div>
		<section class="container-fluid c-header">
			<div class="container">
				<a href="" class="logo">{{ headTitle }}</a>
				<div class="d-sm-none">
					<div href="" class="btn-menu" :class="showMenu?'on':''" @click="showMenu = !showMenu"></div>
				</div>

				<div class="d-none d-sm-flex">
					<router-link class="uc-btn pink" to="/login" v-if="!token">登录</router-link>
					<button class="uc-btn pink" v-if="token" @click="logout">退出登录</button>
					<button class="uc-btn pink" v-if="token" to="" @click="handleButtonClick">我要入驻</button>
				</div>

			</div>
		</section>
		<section class="uc-alert uc-alert-menu" v-if="showMenu">
			<div class="over-close" @click="showMenu = false"></div>
			<div class="box-bottom">
				<div class="title">
					<div class="name">筛选</div>
					<div class="btn-close" @click="showMenu = false"></div>
				</div>
				<!-- 20240923 -->
				<div class="thr-title">正在营业 <sup>{{ business_num }}</sup></div>
				<!-- /20240923 -->
				<div class="sub-title">类型</div>
				<ul class="choose-box">
					<li v-for="(item,index) in list001" :key="index" v-if="item.id>0" class="choose-item" :class="{'on': isSelecteds(item.id)}" @click="toggleSelection(item.id,item)">
						{{ item.name }}
					</li>
				</ul>
				<div class="sub-title">价格区间</div>
				<div class="price-box">
					<div class="hd">
						<el-slider v-model="alertMenuPrice" range :min="100" :max="1000" @change="priceSearch"></el-slider>
					</div>
					<div class="bd">
						<div class="txt">￥100</div>
						<div class="txt">￥1000</div>
					</div>
				</div>
				<ul class="choose-box2">
					<li v-for="(item,index) in listAlertMenu002" :key="index" class="choose-item" :class="item.checked?'on':''" @click="btnChooseAlertMenu002(index)">
						<img src="@/assets/images/radio-on.png" alt="" class="uc-icon24" v-if="item.checked">
						<img src="@/assets/images/radio.png" alt="" class="uc-icon24" v-else>
						{{ item.name }}
					</li>
				</ul>
				<div class="operate">
					<button class="uc-btn gray-light" hover-class="none" @click="toggleSelectionClear">重置</button>
					<button class="uc-btn pink" hover-class="none" @click="checkOk">确定</button>
				</div>
				<div class="other">
				    <div class="btn uc-pink" v-if="token" @click="handleButtonClicktwo">我的广告</div>
					<router-link class="btn" to="/login" v-if="!token">登录</router-link>
					<div class="btn uc-pink" v-if="token" @click="logout">退出登录</div>
					
				</div>
			</div>
		</section>
		<div class="uc-alert uc-alert-AdSetting" v-if="showAdSetting">
			<div class="over-close" @click="showAdSetting=false"></div>
			<div class="box-bottom">
				<div class="title">
					<div class="btn-back" @click="showAdSetting = false"><i class="uc-font uc-arrow-left"></i></div>
					<div class="name">广告内容</div>
					<div class="btn-sure" @click="addsubmit">
						<img src="@/assets/images/icon-sure.png" class="uc-icon50" alt="">
					</div>
				</div>
				<div class="scroll-box">
					<div class="sub-title">营业中
						<el-switch v-model="adddata.is_business" active-color="#F06C92" inactive-color="#aaa"> </el-switch>
					</div>
					<div class="sub-title" v-show="false">认证
						<el-switch v-model="adddata.is_auth" active-color="#F06C92" inactive-color="#aaa"> </el-switch>
					</div>
					<div class="sub-title" v-show="false">在忙
						<el-switch v-model="adddata.is_busy" active-color="#F06C92" inactive-color="#aaa"> </el-switch>
					</div>
					<ul class="form">
						<li>
							<input type="text" class="uc-input" placeholder="我的标题" value="" placeholder-class="uc-phColor" v-model="adddata.mytitle"/>
						</li>
						<li>
							<input
							  type="text"
							  class="uc-input"
							  placeholder="上传头像后显示文件名"
							  readonly
							  :value="selectedFileName || '上传头像'"
							  placeholder-class="uc-phColor"
							/>
							<div class="uc-btn pink btn-upload" @click="triggerFileUpload">
							  上传
							  <input
								type="file"
								@change="onFileChange"
								ref="fileInput"
								style="display: none;"
							  />
							</div>
						  </li>
						<li>
							<!-- <input type="text" class="uc-input" placeholder="坐标位置" value="" placeholder-class="uc-phColor" v-model="adddata.address"/> -->
							<select class="uc-input" v-model="adddata.address">
								<option value="" disabled selected>请选择坐标位置</option>
								<option v-for="(item, index) in actions" :key="index" :value="item.id">{{ item.name }}</option>
								<!-- 可以根据需要添加更多选项 -->
							</select>
						</li>
						<li>
							<input type="text" class="uc-input" placeholder="详情链接" value="" placeholder-class="uc-phColor" v-model="adddata.link"/>
						</li>
					</ul>
					<div class="sub-title">基本信息</div>
					<ul class="form2">
						<li>
							<select name="" class="uc-select" id="" v-model="adddata.age">
								<option value="" disabled selected>{{cfgs.info.age}}</option>
								<option :value="key" v-for="(item,key) in age" :key="key">{{item}}</option>
							</select>
						</li>
						<li>
							<select name="" class="uc-select" id="" v-model="adddata.height">
								<option value="" disabled selected>{{cfgs.info.height}}</option>
								<option :value="key" v-for="(item,key) in heighe" :key="key">{{item}}</option>
							</select>
						</li>
						<li>
							<select name="" class="uc-select" id="" v-model="adddata.weight">
								<option value="" disabled selected>{{cfgs.info.tizhong}}</option>
								<option :value="key" v-for="(item,key) in weight" :key="key">{{item}}</option>
							</select>
						</li>
						<li>
							<select name="" class="uc-select" id="" v-model="adddata.work">
								<option value="" disabled selected>{{cfgs.info.gznx}}</option>
								<option :value="key" v-for="(item,key) in work" :key="key">{{item}}</option>
							</select>
						</li>
					</ul>
					<div class="sub-title">服务选项</div>
					<ul class="choose-box">
						<li v-for="(item,index) in list001" :key="index" class="choose-item" :class="item.checked?'on':''" @click="btnChooseAdSetting001(index)" v-if="item.name !== '正在营业'">
							<i class="uc-font uc-checkbox-on uc-pink" v-if="item.checked"></i>
							<i class="uc-font uc-checkbox" v-else></i>
							{{ item.name }}
						</li>
					</ul>
					<div class="sub-title">收费标准</div>
					<ul class="form2">
						<li v-for="(item, key) in shi" :key="key">
						  <select :name="'shi' + key" class="uc-select" @change="updateSelection('shi'+item.id, $event.target.value)">
							<option value="">{{ item.title }}</option>
							<option v-for="(option, index) in item.options" :value="option" :key="index">
							  {{ option }}
							</option>
						  </select>
						</li>
					</ul>
					<div class="sub-title">工作地点</div>
					<ul class="choose-box">
						<li v-for="(item,index) in listAdSetting002" :key="index" class="choose-item" :class="item.checked?'on':''" @click="btnChooseAdSetting002(index)">
							<i class="uc-font uc-checkbox-on uc-pink" v-if="item.checked"></i>
							<i class="uc-font uc-checkbox" v-else></i>
							{{ item.name }}
						</li>
					</ul>
					<div class="sub-title">联系方式</div>
						<!-- <ul class="form3">
							<li>
								<input class="radio" type="radio" name="contact" style="back">
								<div class="txt">电话</div>
								<input type="text" class="uc-input" placeholder="" value="" placeholder-class="uc-phColor" v-model="adddata.phone"/>
							</li>
							<li>
								<input class="radio" type="radio" name="contact">
								<div class="txt">微信</div>
								<input type="text" class="uc-input" placeholder="" value="" placeholder-class="uc-phColor" v-model="adddata.weixin"/>
							</li>
							<li>
								<input class="radio" type="radio" name="contact">
								<div class="txt">QQ</div>
								<input type="text" class="uc-input" placeholder="" value="" placeholder-class="uc-phColor" v-model="adddata.qq"/>
							</li>
							<li>
								<input class="radio" type="radio" name="contact">
								<div class="txt">Telegram</div>
								<input type="text" class="uc-input" placeholder="" value="" placeholder-class="uc-phColor" v-model="adddata.telegram"/>
							</li>
							<li>
								<input class="radio" type="radio" name="contact">
								<div class="txt">Whatsapp</div>
								<input type="text" class="uc-input" placeholder="" value="" placeholder-class="uc-phColor" v-model="adddata.whatsapp"/>
							</li>
					  	</ul> -->
						<ul class="form3">
							<li v-for="(option, index) in contactOptions" :key="index">
							<!-- {{option.value}} -->
								<div class="btn">
									<img src="@/assets/images/radio-on.png" class="uc-icon20" alt="" v-if="isSelected(option.value)">
									<img src="@/assets/images/radio.png" class="uc-icon20" alt="" v-else>
								</div>
								<input
								class="radio"
								type="radio"
								:id="'contact-' + index"
								name="contact"
								:value="option.value"
								v-model="selectedContact"
								v-show="false"
								>
								<label class="txt" :for="'contact-' + index">{{ option.text }}</label>
								<!-- <div class="txt" :for="'contact-' + index">{{ option.text }}</div> -->
								<input
								type="text"
								class="uc-input"
								:placeholder="option.placeholder"
								:value="contens[option.value] || ''"
								:disabled="!isSelected(option.value)"
								@input="updateData(option.value, $event.target.value)"
								>
							</li>
						</ul>
					<div class="tips">{{youxiao}}</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Vue from 'vue'
import { mapState ,mapMutations} from 'vuex'
import axios from 'axios'
	export default {
		name: '',
		components: {},
		data() {
			return {
				selectedAddress: '',
				selectedOptions: {},
				showMenu: false,
				listAlertMenu001: [],
				listAlertMenu002: [
					{ name: '上门', checked: true },
					{ name: '店内', checked: false },
				],
				alertMenuPrice: [100, 800],
				showAdSetting: false,
				listAdSetting001: [],
				listAdSetting002: [
					{ name: '上门', checked: true },
					{ name: '店内', checked: false },
					],
				searchwhere:{
					title_id: 0,
					limit:0,
					address_id:0,
				},
				age:[],
				heighe:[],
				weight:[],
				work:[],
				shi:[],
				selectedFileName: '',
				actions:[],//位置列表
				adddata: {
					title_id: '',
					address_id: 1,
					address: '',
					mytitle:'',
					age:'',
					height:'',
					weight:'',
					work:'',
					shi1:'',
					shi2:'',
					shi3:'',
					shi4:'',
					link:'',
					phone:'',
					weixin:'',
					qq:'',
					telegram:'',
					whatsapp:'',
					is_business:0,
					is_auth:0,
					is_busy:0,
					upload:'',
				},
				token: '',
				selectedContact: '', // 当前选中的联系方式
				contens: { // 存储联系方式的数据
					phone: '',
					weixin: '',
					qq: '',
					telegram: '',
					whatsapp: ''
				},
				contactOptions: [ // 联系方式选项
					{ text: '电话', value: 'phone', placeholder: '请输入电话号码' },
					{ text: '微信', value: 'weixin', placeholder: '请输入微信号' },
					{ text: 'QQ', value: 'qq', placeholder: '请输入QQ号' },
					{ text: 'Telegram', value: 'telegram', placeholder: '请输入Telegram号' },
					{ text: 'WhatsApp', value: 'whatsapp', placeholder: '请输入WhatsApp号' }
				],
				youxiao:'',
			}
		},
		mounted() {
			this.getSubConfig();
			this.token = localStorage.getItem('token');
			if(this.token){
				this.geteditinfo();
			}
		},
		computed: {
			...mapState([
				'indexList',
				'list001',
				'selectedIndices',
				'business_num',
				'headTitle',
				'cfgs'
			])
		},
		watch: {
			selectedContact(newVal, oldVal) {
			// 当selectedContact变化时，清空其他所有未被选中的输入框的数据
			this.contactOptions.forEach(option => {
				if (option.value !== newVal) {
				this.$set(this.contens, option.value, ''); // 使用$set来确保响应性
				}
			});
			}
		},
		methods: {
			...mapMutations(['SET_SELECTED_INDICES','SET_SELECTED_CLEAR']),
			toggleSelectionClear(){
				this.SET_SELECTED_CLEAR();
				//this.searchwhere.title_id = this.selectedIndices.join(',');
				//this.getList()
			},
			checkOk(){
				this.showMenu = false
				this.searchwhere.title_id = this.selectedIndices.join(',');
				this.getList()
				
			},
			toggleSelection(index,item) {
				// 调用 Vuex mutation 来更新选中的索引
				this.SET_SELECTED_INDICES(index);
				//this.searchwhere.title_id = this.selectedIndices.join(',');
				//this.getList()
			},
			isSelecteds(index) {
			// 检查索引是否在 selectedIndices 数组中
			return this.selectedIndices.includes(index);
			},
			updateData(key, value) {
			// 只更新当前选中的输入框的数据
			this.$set(this.contens, key, value);
			},
			isSelected(value) {
			// 检查当前值是否被选中
			return this.selectedContact === value;
			},
			handleButtonClick() {
				// 首先调用 geteditinfo 方法
				this.geteditinfo();
				// 然后更新 showAdSetting 的值
				this.showAdSetting = true;
			},
			handleButtonClicktwo(){
				this.geteditinfo();
				this.showMenu = false;
				this.showAdSetting = true
			},
			updateSelection(key, value) {
				this.$set(this.selectedOptions, key, value);
			},
			btnChooseAlertMenu001(key,val) {
				this.$store.commit('setselectedIndex', key);
				this.searchwhere.title_id = val.id
				this.searchwhere.limit = val.number
				this.getList()
			},
			btnChooseAlertMenu002(val) {
				this.listAlertMenu002.forEach((item, index) => {
					item.checked = index == val ? true : false
				})
				if(val == 1){
					this.searchwhere.address_id = 2
				}else{
					this.searchwhere.address_id = 1
				}
				this.getList()
			},
			btnChooseAdSetting001(val) {
				this.$store.state.list001.forEach((item, index) => {
					if (index == val) {
						item.checked = !item.checked
					}
				})
			},
			btnChooseAdSetting002(val) {
				this.listAdSetting002.forEach((item, index) => {
					if (index == val) {
						item.checked = !item.checked
					}
				})
			},
			priceSearch(){
				this.getList()
			},
			searchAddress(event) {
				console.log(event);
				const selectedId = event.target.value;
				this.searchwhere.address_id = selectedId
				this.getList()
			},
			getList() {
				axios.get(Vue.prototype.BASE_URL+'/api/index/index', {
				params: {
					title_id: this.searchwhere.title_id,
					limit:this.searchwhere.limit,
					address_id:this.searchwhere.address_id,
					range_num:this.alertMenuPrice[0],
					range_num2:this.alertMenuPrice[1]
				}
				})
				.then(response => {
				// 处理响应
					this.$store.commit('setIndexList', response.data.data.info);
				})
				.catch(error => {
				// 处理错误
					console.error('Error fetching data:', error);
				});
			},
			getSubConfig() {
				let that = this;
				axios.get(Vue.prototype.BASE_URL+'/api/index/getSubConfig', {
				})
				.then(response => {
				// 处理响应
					console.log(response);
					this.age = response.data.data.indexage;
					this.heighe = response.data.data.indexheight;
					this.weight = response.data.data.indexweight;
					this.work = response.data.data.indexwork;
					this.shi = response.data.data.indexshi;
					this.$store.commit('setTitle', response.data.data.headTitle)
					that.actions = response.data.data.adds_list
					console.log(response.data.data.headTitle)
				})
				.catch(error => {
				// 处理错误
					console.error('Error fetching data:', error);
				});
			},
			onFileChange(e) {
				// 处理文件选择变化
				const file = e.target.files[0];
				if (file) {
					this.selectedFileName = file.name; // 更新文件名到输入框
				}
				this.uploadFile(); // 调用上传文件的方法
			},
			triggerFileUpload() {
				// 触发文件选择（通常用于点击上传按钮时）
				this.$refs.fileInput.click(); // 模拟点击文件输入
			},
			uploadFile() {
				// 使用ref获取文件输入元素
				const fileInput = this.$refs.fileInput;

				// 检查是否有文件被选中
				if (!fileInput.files.length) {
					alert('请选择文件');
					return;
				}

				// 创建FormData实例，用于构建要发送的数据
				const formData = new FormData();

				// 将文件添加到FormData实例中
				formData.append('file', fileInput.files[0]);

				// 如果需要，添加额外的数据（如token）
				formData.append('token', localStorage.getItem('token'));

				// 使用Axios发送POST请求到服务器
				// 注意：不要在这里直接传递一个对象，而是传递FormData实例
				axios.post(Vue.prototype.BASE_URL+'/api/common/upload', formData, {
					// 这里的headers是可选的，因为Axios会自动为FormData设置正确的Content-Type
					// 但如果需要，可以添加其他自定义headers
					// headers: {
					//     // 例如，如果需要设置Authorization头部
					//     // 'Authorization': 'Bearer YOUR_TOKEN_HERE' // 注意：这通常用于身份验证，而不是文件上传的token
					// }
				})
				.then(response => {
					// 处理成功情况
					console.log('文件上传成功:', response);
					this.selectedFileName = response.data.data.url;
					this.adddata.upload = this.selectedFileName;
				})
				.catch(error => {
					// 处理错误情况
					console.error('文件上传失败:', error);
				});
			},
			addsubmit(){
				this.adddata.title_id = this.$store.state.list001
				.filter(item => item.name !== '正在营业' && item.checked)
				.map(item => item.id)
				.join(',');

				if(this.listAdSetting002[0].checked == false && this.listAdSetting002[1].checked == false){
					alert('请选择工作地点');
					this.geteditinfo();
					return false;
				}

				this.listAdSetting002.forEach((item, index) => {
					if(item.name == '上门'){
						if(item.checked){
							this.adddata.address_id = 1;
						}
					}else{
						if(item.checked){
							this.adddata.address_id = 2;
						}
					}
				})

				if(this.listAdSetting002[0].checked == true && this.listAdSetting002[1].checked == true){
					this.adddata.address_id = '1,2';
				}


				if(this.contens.phone){
					this.adddata.contact = 'icon-dianhua'
					this.adddata.phone = this.contens.phone
				}
				if(this.contens.weixin){
					this.adddata.contact = 'icon-weixin'
					this.adddata.weixin = this.contens.weixin
				}
				if(this.contens.qq){
					this.adddata.contact = 'icon-qie'
					this.adddata.qq = this.contens.qq
				}
				if(this.contens.telegram){
					this.adddata.contact = 'icon-paper-full'
					this.adddata.telegram = this.contens.telegram
				}
				if(this.contens.whatsapp){
					this.adddata.contact = 'icon-dianhua1'
					this.adddata.whatsapp = this.contens.whatsapp
				}

				if(this.adddata.is_business){
					this.adddata.is_business = 1;
				}else{
					this.adddata.is_business = 0;
				}

				if(this.adddata.is_auth){
					this.adddata.is_auth = 1;
				}else{
					this.adddata.is_auth = 0;
				}

				if(this.adddata.is_busy){
					this.adddata.is_busy = 1;
				}else{
					this.adddata.is_busy = 0;
				}

				if(this.adddata.mytitle == ''){
					alert('请填写标题');
					this.geteditinfo();
					return false;
				}
				if(this.adddata.address == ''){
					alert('请填写地址');
					this.geteditinfo();
					return false;
				}
				if(this.adddata.age == ''){
					alert('请选择年龄');
					this.geteditinfo();
					return false;
				}
				if(this.adddata.height == ''){
					alert('请选择身高');
					this.geteditinfo();
					return false;
				}
				if(this.adddata.weight == ''){
					alert('请选择体重');
					this.geteditinfo();
					return false;
				}
				if(this.adddata.work == ''){
					alert('请选择工作年限');
					this.geteditinfo();
					return false;
				}
				if(this.adddata.title_id == ''){
					alert('请选择服务选项');
					this.geteditinfo();
					return false;
				}
				if(!this.selectedOptions.shi1){
					alert('请选择工费');
					this.geteditinfo();
					return false;
				}
				if(this.adddata.checkName == ''){
					alert('请填写联系方式');
					this.geteditinfo();
					return false;
				}
				// console.log(this.adddata);return false;
				const formData = new FormData();
				formData.append('token', localStorage.getItem('token'));
				formData.append('mytitle', this.adddata.mytitle);
				formData.append('address', this.adddata.address);
				formData.append('age', this.adddata.age);
				formData.append('height', this.adddata.height);
				formData.append('weight', this.adddata.weight);
				formData.append('work', this.adddata.work);
				formData.append('shi1', this.selectedOptions.shi1);
				formData.append('shi2', this.selectedOptions.shi2);
				formData.append('shi3', this.selectedOptions.shi3);
				formData.append('shi4', this.selectedOptions.shi4);
				formData.append('link', this.adddata.link);
				formData.append('phone', this.adddata.phone);
				formData.append('weixin', this.adddata.weixin);
				formData.append('qq', this.adddata.qq);
				formData.append('telegram', this.adddata.telegram);
				formData.append('whatsapp', this.adddata.whatsapp);
				formData.append('is_business', this.adddata.is_business);
				formData.append('is_auth', this.adddata.is_auth);
				formData.append('is_busy', this.adddata.is_busy);
				formData.append('upload', this.selectedFileName);
				formData.append('title_id', this.adddata.title_id);
				formData.append('address_id', this.adddata.address_id);

				
				axios.post(Vue.prototype.BASE_URL+'/api/index/save', formData,{
				})
				.then(response => {
					console.log(response);
					if(response.data.code == 1){
						alert(response.data.msg);
						window.location = "/";
					}else{
						alert(response.data.msg);
					}

					if(response.data.code == 401){
						alert(response.data.msg);
					}
				})
				.catch(error => {
					// 处理错误
					if(error.code == "ERR_BAD_REQUEST"){
						alert('请先登录');
						window.location = "/login";
					}else{
						alert('提交失败');
					}
				});
				
			},
			geteditinfo(){
				let that = this;
				let formData = new FormData();
				formData.append('token', localStorage.getItem('token'));
				axios.post(Vue.prototype.BASE_URL+'/api/index/geteditinfo', formData,{

				})
				.then(response => {
					if(response.data.code == 200){
						that.$store.commit('setlist001checked', response.data.data.title_id);
						that.adddata.title_id = response.data.data.title_id;
						that.listAdSetting002.forEach(item => {
							if(response.data.data.address_id  == '1,2'){
								item.checked = true;
							}
							if(response.data.data.address_id  == 1){
								if(item.name == "上门"){
									item.checked = true;
								}else{
									item.checked = false;
								}
							}
							if(response.data.data.address_id  == 2){
								if(item.name == "店内"){
									item.checked = true;
								}else{
									item.checked = false;
								}
							}
						});
						that.adddata = response.data.data;
						// this.adddata.address_id = response.data.data.address_id;
						this.adddata.address = response.data.data.address == null ? '': response.data.data.address;
						this.adddata.mytitle = response.data.data.mytitle;
						this.adddata.age = response.data.data.age == null ? '': response.data.data.age,
						this.adddata.height = response.data.data.height
						this.adddata.weight = response.data.data.weight
						this.adddata.work = response.data.data.work
						this.adddata.link = response.data.data.link
						if(response.data.data.phone){
							that.selectedContact = 'phone'
							that.contens.phone = response.data.data.phone
						}
						if(response.data.data.weixin){
							that.selectedContact = 'weixin'
							that.contens.weixin = response.data.data.weixin
						}
						if(response.data.data.qq){
							that.selectedContact = 'qq'
							that.contens.qq = response.data.data.qq
						}
						if(response.data.data.telegram){
							that.selectedContact = 'telegram'
							that.contens.telegram = response.data.data.telegram
						}
						if(response.data.data.whatsapp){
							that.selectedContact = 'whatsapp'
							that.contens.whatsapp = response.data.data.whatsapp
						}
						if(response.data.data.is_business == 1){
							that.adddata.is_business = true
						}else{
							that.adddata.is_business = false
						}

						if(response.data.data.is_auth == 1){
							that.adddata.is_auth = true
						}else{
							that.adddata.is_auth = false
						}

						if(response.data.data.is_busy == 1){
							that.adddata.is_busy = true
						}else{
							that.adddata.is_busy = false
						}
						that.selectedFileName = response.data.data.upload

						that.youxiao = response.data.data.youxiao
                    }
				})
				.catch(error => {
					if(error.code == "ERR_BAD_REQUEST"){
						alert('请先登录');
						window.location = "/login";
					}
				});
			},
			logout(){
				const formData = new FormData();
				formData.append('token', localStorage.getItem('token'));
				axios.post(Vue.prototype.BASE_URL+'/api/user/logout', formData,{

				})
				.then(response => {
					if(response.data.code == 200){
                        alert(response.data.msg);
						localStorage.removeItem('token');
						localStorage.removeItem('userinfo');
                        window.location = "/";
                    }else{
                        alert(response.data.msg);
                    }
				})
				.catch(error => {
				// 处理错误
					console.error('Error fetching data:', error);
				});
			}
		},
	}
</script>
<style scoped>
	.uc-alert-menu .price-box .hd>>>.el-slider__bar {
		background-color: #F06C92;
	}

	.uc-alert-menu .price-box .hd>>>.el-slider__button {
		background-color: #fff;
		border-color: #F06C92;
	}
	.radio{
		left: 34px;
		top: 723px;
		width: 16px;
		height: 16px;
		opacity: 1;
		background-color: rgba(240, 108, 146, 1);

	}
</style>